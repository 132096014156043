<template>
  <div>

    <validation-observer
      tag="div"
      v-for="(item, itemIndex) in modelItems"
      :ref="`${slotConfig.name}_${itemIndex}`"
      :vid="`${slotConfig.name}_${itemIndex}`"
      :key="item.id"
      class="mb-05"
    >

      <validation-provider
        tag="b-row"
        #default="{ errors }"
        :ref="`${slotConfig.name}_${itemIndex}_main`"
        :vid="`${slotConfig.name}_${itemIndex}_main`"
        :name="$t(slotConfig.label)"
        :customMessages="slotConfig.custom_messages"
        :rules="slotConfig.rule"
      >
        <b-col cols="12" class="d-flex">
          <div class="flex-grow-1">
            <b-form-input
              :type="slotConfig.type"
              :value="item.value"
              :disabled="(slotConfig.add_disable === true && !isEdit) || (slotConfig.edit_disable === true && isEdit)"
              @input="onInput(itemIndex, arguments[0])"
              @blur="onBlur(itemIndex, arguments[0])"
              :class="slotConfig.input_class"
            />
          </div>
          <b-button
            v-if="showDeleteBtn"
            class="flex-shrink-0 ml-1"
            variant="outline-danger"
            @click="deleteItem(itemIndex)"
            :disabled="modelItems.length <= slotConfig.min_count"
          >
            <!--{{ $t('common.delete') }}-->
            <feather-icon icon="MinusCircleIcon" />
          </b-button>
        </b-col>
        <b-col
          cols="12"
          v-show="errors.length !== 0 && (!slotConfig.check_duplicate || !duplicateItems.includes(item.value))"
        ><small class="text-danger">{{ errors[0] }}</small></b-col>
      </validation-provider>

      <validation-provider
        tag="b-row"
        :ref="`${slotConfig.name}_${itemIndex}_sub`"
        #default="{ errors }"
        :vid="`${slotConfig.name}_${itemIndex}_sub`"
        :name="$t(slotConfig.label)"
        :customMessages="{ length: slotConfig.duplicate_tips}"
        rules="length:0"
        v-if="slotConfig.check_duplicate"
        v-show="duplicateItems.includes(item.value)"
      >
        <input type="hidden" :value="duplicateItems.includes(item.value) ? '_' : ''"/>
        <b-col cols="12" v-show="errors.length !== 0"><small class="text-danger">{{ errors[0] }}</small></b-col>
      </validation-provider>

    </validation-observer>

    <b-button
      v-if="showAddBtn" variant="outline-primary" @click="addItem()"
      :disabled="modelItems.length >= slotConfig.max_count"
    >
      <!--{{ $t('common.add') }}-->
      <feather-icon icon="PlusCircleIcon" />
    </b-button>

  </div>
</template>

<script>
// import common from '@/common'
// import _ from 'lodash'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import '@validations'
import { getDuplicateItems } from '@/libs/ez-utils'

export default {
  name: 'FieldInputs',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false
    },
    input_class: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      default: () => []
    },
    rootItem: {
      type: Object,
      default: () => ({})
    },
    showDeleteBtn: {
      type: Boolean,
      default: true
    },
    showAddBtn: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      autoIndex: 0,
      modelItems: [],
      emptyValue: '',
      slotConfig: Object.assign({
        type: 'text',
        min_count: this.rootItem.rule?.split('|')
          .includes('required') ? 1 : 0,
        max_count: 99,
        check_duplicate: false,
        duplicate_tips: this.$t('common.duplicate_content_tips')
      }, this.rootItem.slot_config)
    }
  },

  computed: {
    duplicateItems() {
      return this.slotConfig.check_duplicate
        ? getDuplicateItems(this.modelItems.map(item => item.value).filter(value => value !== ''))
        : []
    },
  },

  watch: {
    items: {
      handler(values, _oldValues) {
        for (const [i, value] of values.entries()) {
          while (this.modelItems.length >= i + 1 && value !== this.modelItems[i].value) {
            this.deleteItem(i, false)
          }
          if (this.modelItems.length < i + 1) {
            this.addItem(value)
          }
        }
      },
      deep: true,
    }
  },

  created() {
    this.modelItems = this.items.map((value, index) => ({
      value,
      id: this.autoIndex++
    }))
    const remainCounts = this.slotConfig.min_count - this.modelItems.length
    for (let i = 0; i < remainCounts; i += 1) {
      this.addItem()
    }
  },

  methods: {
    addItem: function (value = this.emptyValue, isUpdate = true) {
      this.modelItems.push({
        value,
        id: this.autoIndex++
      })
      isUpdate && this.updateValues()
    },
    deleteItem: function (itemIndex, isUpdate = true) {
      this.modelItems.splice(itemIndex, 1)
      isUpdate && this.updateValues()
    },
    onInput(itemIndex, value, isUpdate = true) {
      this.modelItems[itemIndex].value = value
      isUpdate && this.updateValues()
    },
    onBlur(itemIndex, value) {
      if (this.slotConfig.check_duplicate) {
        this.modelItems.forEach((v, i) => {
          this.$refs[`${this.slotConfig.name}_${i}_sub`][0].validate()
        })
      }
    },
    updateValues() {
      this.$emit('updateValues', this.modelItems.map(item => item.value))
    },
    buildData() {
    }
  },
}
</script>

<style lang="scss" scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}
</style>
