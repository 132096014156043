import common from '@/common'
import Vue from 'vue'

let form_options = {}
form_options.company = []
form_options.status = []

let showRfidTags = false
const isShowSQK = ['KioskHK'].includes(process.env.VUE_APP_API_PROJ_TAG)

export function reloadFieldData() {
  // init Arrays
  ;[
    form_options.company,
    form_options.status,
  ].forEach(arr => arr.length = 0)

  // Todo: no need to call this whilst account is not admin
  common.getCompanyOptions()
    .then(res => {
      form_options.company.push.apply(form_options.company, res)
    })

  if (common.isAdmin()) {
    showRfidTags = true
  } else {
    common.apiGetData('/facility/GetFacilityAvailableModules?facilityID=' + common.getFacilityId())
      .then(res => {
        showRfidTags = res.data.modules.some(module => module.code === 'GeoFencing_Menu')
      })
  }

  common.getMemberStatusOptions()
    .then(res => {
      form_options.status.push.apply(form_options.status, res)
    })
}

export default [
  {
    name: 'photo',
    label: 'member.photo',
    type: 'slot',
    slot: 'photo'
  },
  {
    name: 'companyID',
    label: 'common.company',
    type: 'select',
    rule: 'required',
    options: form_options.company,
    show: common.isAdmin(),
    edit_disable: true
  },
  {
    name: 'facilityID',
    label: 'common.facility',
    type: 'slot',
    rule: 'required',
    slot: 'facility',
    show: common.isAdmin()
  },
  {
    name: 'floorID',
    label: 'common.floor',
    type: 'slot',
    slot: 'floor',
  },
  {
    name: 'roomID',
    label: 'common.room',
    type: 'slot',
    slot: 'room',
  },
  {
    name: 'bedID',
    label: 'common.bed',
    type: 'slot',
    slot: 'bed',
  },
  {
    name: 'memberCode',
    label: 'member.memberCode',
    type: 'text',
    edit_show: true,
    edit_disable: true
  },
  {
    name: 'refCode',
    label: 'member.refCode',
    type: 'text',
    rule: 'required|max: 20'
  },
  {
    name: 'chineseName',
    label: 'member.chineseName',
    type: 'text',
    rule: 'required|max: 20'
  },
  {
    name: 'englishName',
    label: 'member.englishName',
    type: 'text',
    rule: 'max: 50'
  },
  {
    name: 'hkid',
    label: 'member.hkid',
    type: 'text',
    rule: 'max: 20'
  },
  {
    name: 'emergencyPerson',
    label: 'member.emergencyPerson',
    type: 'text',
    rule: 'max: 50'
  },
  {
    name: 'emergencyPhone',
    label: 'member.emergencyPhone',
    type: 'text',
    rule: 'digits: 8'
  },
  {
    name: 'isMale',
    label: 'member.sex',
    type: 'radio',
    options: [
      {
        text: common.getI18n('member.sex_male'),
        value: true
      },
      {
        text: common.getI18n('member.sex_female'),
        value: false
      },
    ],
    rule: 'required'
  },
  {
    name: 'birthDate',
    label: 'member.birthDate',
    type: 'date',
    // rule: 'required'
  },
  {
    name: 'joinedDate',
    label: 'member.joinedDate',
    type: 'date',
    rule: 'required',
    default: Vue.prototype.$moment()
      // .add(-6, 'days')
      .format('YYYY-MM-DD')
  },
  {
    name: 'status',
    label: 'member.status',
    type: 'select',
    options: form_options.status,
    rule: 'required'
  },

  {
    type: 'column-separator'
  },

  {
    label: 'common.login_associated',
    type: 'title',
    add_show: false,
    edit_show: false,
    show_fn: _vm => showRfidTags,
  },
  {
    name: 'nfcTags',
    label: 'common.nfc_tag',
    rule: 'required',
    type: 'slot',
    add_show: false,
    edit_show: false,
    validate_in_slot: true,
    slot: 'nfcTags',
    slot_config: {
      name: 'nfc',
      label: 'common.nfc_tag',
      rule: 'required|max:100',
      type: 'text',
      min_count: 1,
      max_count: 99,
      input_class: "text-monospace",
      check_duplicate: true,
    },
  },
  {
    name: 'qrCode',
    label: 'QRCode',
    type: 'text',
    add_show: false,
    edit_show: false,
    edit_disable: true
  },
  {
    name: 'qrCodes',
    label: 'QRCode (外部)',
    // rule: 'required',
    validate_in_slot: true,
    type: 'slot',
    add_show: false,
    edit_show: false,
    slot: 'qrCodes',
    slot_config: {
      name: 'qrCodes',
      label: 'QRCode (外部)',
      type: 'file',
      file_accept: '.png,.jpg,.jpeg,.jfif',
      placeholder: common.getI18n('kiosk.placeholder').replace('{_type_}', '.png, .jpg, .jpeg, .jfif'),
      rule: 'required',
      max_count: 9,
      input_class: "text-monospace",
      show_del_btn: true,
      show_add_btn: true,
    },
  },

  {
    label: 'common.login_associated',
    type: 'title',
    add_show: false,
    edit_show: false,
    show_fn: _vm => showRfidTags,
  },
  {
    label: 'geo_fencing.geo_fencing',
    type: 'title',
    add_show: false,
    edit_show: true,
    show_fn: _vm => showRfidTags,
  },
  {
    name: 'rfidTags',
    label: 'geo_fencing.rfids',
    // rule: 'required',
    type: 'slot',
    validate_in_slot: true,
    add_show: false,
    edit_show: true,
    // show_fn: (vm) => (vm.rowData['rfidTags'] && vm.rowData['rfidTags'].length > 0),
    show_fn: _vm => showRfidTags,
    slot: 'rfidTags',
    slot_config: {
      name: 'member-rfids',
      label: 'geo_fencing.rfids',
      // rule: 'required|max: 50',
      type: 'text',
      min_count: 1,
      max_count: 99,
      edit_disable: true,
      input_class: 'text-monospace',
      check_duplicate: false,
    },
  },

  {
    label: '侍親康相關',
    type: 'title',
    add_show: isShowSQK,
    edit_show: isShowSQK,
  },
  {
    name: 'isTarnsferToSqk',
    label: 'SQK.transferToKangBao',
    type: 'radio',
    options: [
      {
        text: common.getI18n('common.yes'),
        value: true
      },
      {
        text: common.getI18n('common.no'),
        value: false
      },
    ],
    default: false,
    rule: 'required',
    add_show: isShowSQK,
    edit_show: isShowSQK,
    edit_disable: false,
  },
  {
    name: 'sqkAreaCode',
    label: 'SQK.areaCode',
    type: 'text',
    add_show: isShowSQK,
    edit_show: isShowSQK,
    edit_disable: false,
    rule: 'required',
    show_fn: vm => vm.rowData.isTarnsferToSqk === true,
  },
  {
    name: 'sqkMobile',
    label: 'SQK.mobileNum',
    type: 'text',
    add_show: isShowSQK,
    edit_show: isShowSQK,
    edit_disable: false,
    rule: 'required',
    show_fn: vm => vm.rowData.isTarnsferToSqk === true,
  },
]
